export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"636dae5ff45b7e00247b2593",
				"636dae5ff45b7e00247b2596"
			]
		},
		"636dae5ff45b7e00247b2593": {
			"id": "636dae5ff45b7e00247b2593",
			"name": "404",
			"pageUrl": "404"
		},
		"636dae5ff45b7e00247b2596": {
			"id": "636dae5ff45b7e00247b2596",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}